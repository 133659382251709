#m6records {
	height: 100vh;
    overflow: hidden;
}
.m6Video video {
    max-width: 100%;
    width: 100%;
}
.m6Shadow {
    background-color: var(--blacktransparent);
    height: 100vh;
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 2;
}
.m6Logo {
    background-color: var(--whitetransparent);
    position: relative;
    top: -200vh;
    z-index: 5;
    
}
.m6Logo img {
    width: 25%;
    margin: 10px 0px;
}
.m6Sobre {
    background-color: var(--white);
    position: relative;
    top: -190vh;
    font-size: 1.3rem;
    padding: 30px;
    border-radius: 10px;
}