/* set colors vars */
:root {
    --black: #191414;
    --blacktransparent: rgba(0,0,0,0.7);
    --white: #fff;
    --whitetransparent: hsla(0,0%,100%,0.8);
    --pink: #de3075;
    --red: #e33b2e;
    --orange: #e25b2c;
    --lightorange: #f79c31;
    --yellow: #f4d13f;
    --facebook: #4267b2;
    --instagram: #833ab4;
    --twitter: #1da1f2;
    --transparent: transparent;
    --danger: #fca9a9;
}

/* dom element definitions */
html {
  scroll-behavior: smooth;
}
body {
  margin: 0px 0px!important;
  padding: 0px 0px!important;
  width: 100%;
  height: 100vh;
}

/* color classes */
.black {
  color: var(--black);
}
.white {
  color: var(--white);
}

/* background classes */
.blackbg {
  background-color: var(--black);
}
.blacktransparentbg {
  background-color: var(--blacktransparent);
}
.whitebg {
  background-color: var(--white);
}
.whitetransparentbg {
  background-color: var(--whitetransparent);
}
.dangerbg {
  background-color: var(--danger);
}
/* font definitions */
.roboto {
	font-family: 'Roboto Condensed', sans-serif;
}
.poppins {
  font-family: 'Poppins', sans-serif;
}
.comfortaa {
  font-family: 'Comfortaa', cursive;
}

/* alignment */
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

/* bootstrap fixes */
.row {
    margin: 0px;
    --bs-gutter-x: none!important;
    --bs-gutter-y: none!important;
}

/* sizes */
.percent100 {
  width: 100%;
}

.percent80 {
  width: 80%;
  margin: 0 auto;
}

.overflowHidden {
  overflow: hidden;
}

.hidden {
  opacity: 0!important;
}

.blur {
  filter: blur(4px);
}
.grayscale {
  filter: grayscale(100%);
}

.alertMessage {
  font-weight: 200;
  border-radius: 0.375rem;
  margin: 10px 0px;
  padding: 5px 0px;
  border: 1px solid #a0a0a0;
}

.close {
  font-size: 1.2rem;
  cursor: pointer;
}
.input-group-text {
  margin: 10px;
  border-radius: 0 0.375rem 0.375rem 0!important;
  width: 20%;
}
.caution-bg {
  background-color: #e6cc00!important;
  color: var(--black)!important;
}
.alert-bg {
  background-color: var(--octred)!important;
  color: var(--white)!important;
}
.hidden { 
  display: none;
}
.blur {
  filter: blur(4px);
}
.grayscale {
  filter: grayscale(100%);
}
.overflowHidden {
  overflow: hidden;
}