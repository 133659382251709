.notContainer {
	max-width: 80%!important;
	padding: 30px!important;
	border-radius: 0.375rem!important;
	position: absolute!important;
	top: 0%!important;
	left: 50%!important;
	transform: translateX(-50%)!important;
	font-weight: 200;
	font-size: 2rem;
}
.notContainer img {
	width: 20%;
}
.notContainer span {
	display: block;
}
.norRanma img {
	width: 40%;
}