/* 
Filename: spinner.css;
Purpose: css definitions for loading;
Author: Cello Nascimento;
Email: cello@m6prod.com;
*/
.spinnerContainer {
    background-color: var(--transparent);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow: hidden;
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: auto;
}
.loadingContent {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30%;
    transform: translate(-50%, -50%);
}
.loadingContent img {
  max-width: 100%;
}
.spinner {
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translate(-50%, -50%);
}
