#form {
    background-color: var(--orange);
    background: url('../img/hexorange.png');
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.formTitle {
    display: block;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--orange);
    margin-bottom: 20px;
    border-bottom: 3px dotted var(--orange);
}
.formText {
    background-color: var(--white);
    margin: 50px 0px;
    padding: 30px;
    font-size: 1rem;
    font-weight: 200;
    border-radius: 10px;
    border: 3px solid var(--orange); 
}
.formText img {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.formResponse {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
}
.fieldLabel {
    display: block;
}
.fieldDiv input, .fieldDiv textarea {
    border-radius: 10px;
    border: 2px solid var(--orange);
    width: 100%;
    font-size: 1.5rem;
    padding: 5px 10px;
    position: relative;
    z-index: 2;
}
.fieldDiv textarea {
    margin-bottom: -10px;
}
.fieldDiv input:focus, .fieldDiv textarea:focus {
    box-shadow: 0 0 15px var(--orange);   
    border: 2px solid var(--orange);
}
.formSubmit {
    text-align: right;
}
.formSubmit button {
    border-radius: 10px;
    border: 2px solid var(--orange);
    color: var(--white);
    background-color: var(--orange);
    padding: 5px 10px;
}
.formSubmit button:hover {
    box-shadow: 0 0 15px var(--orange);   
}
.contactDanger {
    border-radius: 10px;
    padding-top: 15px;
    position: relative;
    top: -15px;
    z-index: 1;
    font-weight: 700;
}