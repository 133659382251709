#footer {
	background-color: var(--black);
	padding: 50px 0px;
}

.footerLogo img {
    height: 80px;
    margin: 0px 20px;
}

.footerLogo a {
    color: var(--white);
    transition: 1s;
    text-decoration: none;
    font-size: 3rem;
    margin: 5px 15px;
}
.footerLogo a:hover {
    color: var(--pink);
}