#portfolio {
	background-color: var(--red);
    background: url('../img/hexred.png');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: repeat-y;
    border-bottom: 15px solid var(--white);
}
.portfolioText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--red); 
}
.portfolioTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--red);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--red);
}
.portfolioContent {
	background-color: var(--red);
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	transition: 0.4s;
}
.portfolioContent:hover {
	background-color: var(--lightorange);
}
.portfolioContent img {
	max-width: 100%;
	border-radius: 10px;
	aspect-ratio: 16/9;
    object-fit: contain;
}