@media (max-width: 820px) {
    /* MOBILE FIX */
    .percent80 {
        width: 95%!important;
        margin: 0 auto!important;
    }
    .menuDesktop {
        width: 85%;
        left: -85%;
    }
    .toggleActive {
        left: 85%!important;
    }
    .videoHeader video {
        max-width: unset;
        width: unset;
        height: 100vh;
        position: relative;
        left: -110%;
    }
    .homeLogo img {
        width: 70%;
    }
    .lightVideoWrapper iframe {
        height: 30vh;
    }
    .lightVideoTitle {
        font-size: 1rem;
    }
    #m6records {
        height: 100vh;
        overflow: hidden; 
    }

    .m6Shadow {
        top: -116vh;
        height: 110vh;
    }
    .m6Logo {
        position: relative;
        top: -216vh;
    }
    .m6Sobre {
        top: -213vh;
        font-size: 1rem;
        padding: 20px 10px;
        z-index: 10;
    }
    .m6Video video {
        max-width: unset;
        width: unset;
    }
    .m6Logo img {
        width: 35%;
    }
    .albumDate {
        font-size: 1rem;
        margin: 0px 0px;
    }
    .albumDesc {
        font-size: 1rem;
        line-height: 1rem;
    }
    .footerLogo img {
        height: 80px;
        margin: 10px 20px;
    }
}    
