#header {

}
.videoHeader {
    height: 100vh;
    overflow: hidden;
}
.videoHeader video {
    max-width: 100%;
    width: 100%;
}
.videoShadow {
    background-color: var(--blacktransparent);
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}
.homeLogo {
    background-color: var(--whitetransparent);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    
}
.homeLogo img {
    width: 20%;
    margin: 10px 0px;
}
.menuDesktop {
    position: fixed;
    background-color: var(--black);
    height: 100vh;
    width: 24%;
    z-index: 15;
    border-right: 3px solid var(--white);
    left: -24%;
    transition: 0.7s;
}
.menuDesktop span {
    font-size: 1.6rem;
    padding: 15px 20px;
    display: block;
    cursor: pointer;
    transition: 0.4s;
    width: 95%;
    border-bottom: 3px solid var(--black);
    border-radius: 10px;
    height: 10%;
}
.menuDesktop span:hover {
    color: var(--pink)!important;
    border-bottom: 3px solid var(--pink);
    font-size: 1.8rem!important;
}
.menuDesktop span div {
    display: inline-block;
}
.menuDesktop i {
    font-size: 1.5rem;
    position: relative;
    top: 2px;
    padding: 0px 10px;
}
.menuDesktop a i {
    font-size: 3rem;
    transition: 0.4s;
}
.menuDesktop a i:hover {
    font-size: 3.5rem;
}
.menuDesktop a {
    color: var(--white);
    transition: 0.4s;
}
.menuDesktop a:hover {
    color: var(--pink);
}
.toggleMenu {
    position: fixed;
    z-index: 11;
    font-size: 2rem;
    left: 0;
    top: 10px;
    transition: 0.7s;
}
.toggleMenu i {
    background-color: var(--black);
    padding: 12px;
    border-radius: 0px 10px 10px 0px;
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
    transition: 0.4s;
    cursor: pointer;
}
.toggleMenu i:hover {
    color: var(--pink)!important;
}
.menuActive {
    left: 0!important;
}
.toggleActive {
    left: 24%!important;
}
