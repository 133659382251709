#header {

}
.videoHeader {
    height: 100vh;
    overflow: hidden;
}
.videoHeader video {
    max-width: 100%;
    width: 100%;
}
.videoShadow {
    background-color: var(--blacktransparent);
    height: 100vh;
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}
.homeLogo {
    background-color: var(--whitetransparent);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 5;
    
}
.homeLogo img {
    width: 20%;
    margin: 10px 0px;
}
.menuDesktop {
    position: fixed;
    background-color: var(--black);
    height: 100vh;
    width: 24%;
    z-index: 15;
    border-right: 3px solid var(--white);
    left: -24%;
    transition: 0.7s;
}
.menuDesktop span {
    font-size: 1.6rem;
    padding: 15px 20px;
    display: block;
    cursor: pointer;
    transition: 0.4s;
    width: 95%;
    border-bottom: 3px solid var(--black);
    border-radius: 10px;
    height: 10%;
}
.menuDesktop span:hover {
    color: var(--pink)!important;
    border-bottom: 3px solid var(--pink);
    font-size: 1.8rem!important;
}
.menuDesktop span div {
    display: inline-block;
}
.menuDesktop i {
    font-size: 1.5rem;
    position: relative;
    top: 2px;
    padding: 0px 10px;
}
.menuDesktop a i {
    font-size: 3rem;
    transition: 0.4s;
}
.menuDesktop a i:hover {
    font-size: 3.5rem;
}
.menuDesktop a {
    color: var(--white);
    transition: 0.4s;
}
.menuDesktop a:hover {
    color: var(--pink);
}
.toggleMenu {
    position: fixed;
    z-index: 11;
    font-size: 2rem;
    left: 0;
    top: 10px;
    transition: 0.7s;
}
.toggleMenu i {
    background-color: var(--black);
    padding: 12px;
    border-radius: 0px 10px 10px 0px;
    border-top: 3px solid var(--white);
    border-right: 3px solid var(--white);
    border-bottom: 3px solid var(--white);
    transition: 0.4s;
    cursor: pointer;
}
.toggleMenu i:hover {
    color: var(--pink)!important;
}
.menuActive {
    left: 0!important;
}
.toggleActive {
    left: 24%!important;
}

#sobre {
    background-color: var(--pink);
    background: url(/static/media/hexpink.4f654283.png);
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.sobreTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--pink);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--pink);
}
.sobreText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--pink); 
}
/* 
Filename: lightbox.css;
Purpose: css definitions for lightbox;
Author: Cello Nascimento;
Email: cello@m6prod.com;
*/
.lightBox {
  position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 100;
    transition: 1s;
}
.lightBoxActive {
  display: block!important;
}
.lightVideoWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 90%;
}
.lightVideoWrapper i {
    font-size: 3rem;
    display: block;
}
.lightVideoWrapper a {
    color: var(--white);
    text-decoration: none;
    position: relative;
    top: 20px;
    transition: 0.4s;
}
.lightVideoWrapper a:hover {
    color: #1DB954;
}    
.lightVideoWrapper iframe {
    width: 100%;
    height: 70vh;
    border-radius: 10px;
    border: 2px solid var(--white);
}
.lightVideoWrapper img {
    width: 40%;
}
.lightVideoTitle {
    font-size: 2rem;
    font-weight: 500;
    display: block;
}
.lightVideoDesc {
    line-height: 1rem;
    font-weight: 300;
}
.lightVideoClose {
    max-width: 50px;
    position: absolute;
    right: 1%;
    top: 1%;
}
.lightVideoClose i {
    font-size: 3rem;
    cursor: pointer;
    transition: 0.4s;
}
.lightVideoClose i:hover {
    color: var(--pink);
}
.lightVideoDate {
    display: block;
}
.albumCover img {
    width: 70%;
    border-radius: 10px;
    border: 5px solid var(--white);
}
.albumTitle {
    font-size: 2.5rem;
}
.albumDate {
    font-size: 1.5rem;
    margin: 20px 0px;
}
.albumDesc {
    font-size: 1.5rem;
    line-height: 2rem;
}
#portfolio {
	background-color: var(--red);
    background: url(/static/media/hexred.4fe712c3.png);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: repeat-y;
    border-bottom: 15px solid var(--white);
}
.portfolioText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--red); 
}
.portfolioTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--red);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--red);
}
.portfolioContent {
	background-color: var(--red);
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	transition: 0.4s;
}
.portfolioContent:hover {
	background-color: var(--lightorange);
}
.portfolioContent img {
	max-width: 100%;
	border-radius: 10px;
	aspect-ratio: 16/9;
    object-fit: contain;
}
#m6records {
	height: 100vh;
    overflow: hidden;
}
.m6Video video {
    max-width: 100%;
    width: 100%;
}
.m6Shadow {
    background-color: var(--blacktransparent);
    height: 100vh;
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;
    z-index: 2;
}
.m6Logo {
    background-color: var(--whitetransparent);
    position: relative;
    top: -200vh;
    z-index: 5;
    
}
.m6Logo img {
    width: 25%;
    margin: 10px 0px;
}
.m6Sobre {
    background-color: var(--white);
    position: relative;
    top: -190vh;
    font-size: 1.3rem;
    padding: 30px;
    border-radius: 10px;
}
#lancamentos {
    background-color: var(--lightorange);
    background: url(/static/media/hexlightorange.fb590f29.png);
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.lancamentosTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--lightorange);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--lightorange);
}
.lancamentosText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--lightorange); 
}
.lancamentosContent {
	background-color: var(--lightorange);
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	transition: 0.4s;
}
.lancamentosContent:hover {
	background-color: var(--pink);
}
.lancamentosContent img {
	max-width: 100%;
	border-radius: 10px;
	aspect-ratio: 1/1;
    object-fit: contain;
}
#form {
    background-color: var(--orange);
    background: url(/static/media/hexorange.68f95642.png);
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.formTitle {
    display: block;
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--orange);
    margin-bottom: 20px;
    border-bottom: 3px dotted var(--orange);
}
.formText {
    background-color: var(--white);
    margin: 50px 0px;
    padding: 30px;
    font-size: 1rem;
    font-weight: 200;
    border-radius: 10px;
    border: 3px solid var(--orange); 
}
.formText img {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}
.formResponse {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
}
.fieldLabel {
    display: block;
}
.fieldDiv input, .fieldDiv textarea {
    border-radius: 10px;
    border: 2px solid var(--orange);
    width: 100%;
    font-size: 1.5rem;
    padding: 5px 10px;
    position: relative;
    z-index: 2;
}
.fieldDiv textarea {
    margin-bottom: -10px;
}
.fieldDiv input:focus, .fieldDiv textarea:focus {
    box-shadow: 0 0 15px var(--orange);   
    border: 2px solid var(--orange);
}
.formSubmit {
    text-align: right;
}
.formSubmit button {
    border-radius: 10px;
    border: 2px solid var(--orange);
    color: var(--white);
    background-color: var(--orange);
    padding: 5px 10px;
}
.formSubmit button:hover {
    box-shadow: 0 0 15px var(--orange);   
}
.contactDanger {
    border-radius: 10px;
    padding-top: 15px;
    position: relative;
    top: -15px;
    z-index: 1;
    font-weight: 700;
}
#footer {
	background-color: var(--black);
	padding: 50px 0px;
}

.footerLogo img {
    height: 80px;
    margin: 0px 20px;
}

.footerLogo a {
    color: var(--white);
    transition: 1s;
    text-decoration: none;
    font-size: 3rem;
    margin: 5px 15px;
}
.footerLogo a:hover {
    color: var(--pink);
}
/* 
Filename: spinner.css;
Purpose: css definitions for loading;
Author: Cello Nascimento;
Email: cello@m6prod.com;
*/
.spinnerContainer {
    background-color: var(--transparent);
    width: 100%;
    height: 100vh;
    overflow: hidden;
    overflow: hidden;
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: auto;
}
.loadingContent {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.loadingContent img {
  max-width: 100%;
}
.spinner {
    position: absolute;
    left: 50%;
    top: 20%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.notContainer {
	max-width: 80%!important;
	padding: 30px!important;
	border-radius: 0.375rem!important;
	position: absolute!important;
	top: 0%!important;
	left: 50%!important;
	-webkit-transform: translateX(-50%)!important;
	        transform: translateX(-50%)!important;
	font-weight: 200;
	font-size: 2rem;
}
.notContainer img {
	width: 20%;
}
.notContainer span {
	display: block;
}
.norRanma img {
	width: 40%;
}
/* set colors vars */
:root {
    --black: #191414;
    --blacktransparent: rgba(0,0,0,0.7);
    --white: #fff;
    --whitetransparent: hsla(0,0%,100%,0.8);
    --pink: #de3075;
    --red: #e33b2e;
    --orange: #e25b2c;
    --lightorange: #f79c31;
    --yellow: #f4d13f;
    --facebook: #4267b2;
    --instagram: #833ab4;
    --twitter: #1da1f2;
    --transparent: transparent;
    --danger: #fca9a9;
}

/* dom element definitions */
html {
  scroll-behavior: smooth;
}
body {
  margin: 0px 0px!important;
  padding: 0px 0px!important;
  width: 100%;
  height: 100vh;
}

/* color classes */
.black {
  color: #191414;
  color: var(--black);
}
.white {
  color: #fff;
  color: var(--white);
}

/* background classes */
.blackbg {
  background-color: #191414;
  background-color: var(--black);
}
.blacktransparentbg {
  background-color: rgba(0,0,0,0.7);
  background-color: var(--blacktransparent);
}
.whitebg {
  background-color: #fff;
  background-color: var(--white);
}
.whitetransparentbg {
  background-color: hsla(0,0%,100%,0.8);
  background-color: var(--whitetransparent);
}
.dangerbg {
  background-color: #fca9a9;
  background-color: var(--danger);
}
/* font definitions */
.roboto {
	font-family: 'Roboto Condensed', sans-serif;
}
.poppins {
  font-family: 'Poppins', sans-serif;
}
.comfortaa {
  font-family: 'Comfortaa', cursive;
}

/* alignment */
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}

/* bootstrap fixes */
.row {
    margin: 0px;
    --bs-gutter-x: none!important;
    --bs-gutter-y: none!important;
}

/* sizes */
.percent100 {
  width: 100%;
}

.percent80 {
  width: 80%;
  margin: 0 auto;
}

.overflowHidden {
  overflow: hidden;
}

.hidden {
  opacity: 0!important;
}

.blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
}
.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.alertMessage {
  font-weight: 200;
  border-radius: 0.375rem;
  margin: 10px 0px;
  padding: 5px 0px;
  border: 1px solid #a0a0a0;
}

.close {
  font-size: 1.2rem;
  cursor: pointer;
}
.input-group-text {
  margin: 10px;
  border-radius: 0 0.375rem 0.375rem 0!important;
  width: 20%;
}
.caution-bg {
  background-color: #e6cc00!important;
  color: #191414!important;
  color: var(--black)!important;
}
.alert-bg {
  background-color: var(--octred)!important;
  color: #fff!important;
  color: var(--white)!important;
}
.hidden { 
  display: none;
}
.blur {
  -webkit-filter: blur(4px);
          filter: blur(4px);
}
.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.overflowHidden {
  overflow: hidden;
}
@media (max-width: 820px) {
    /* MOBILE FIX */
    .percent80 {
        width: 95%!important;
        margin: 0 auto!important;
    }
    .menuDesktop {
        width: 85%;
        left: -85%;
    }
    .toggleActive {
        left: 85%!important;
    }
    .videoHeader video {
        max-width: unset;
        width: unset;
        height: 100vh;
        position: relative;
        left: -110%;
    }
    .homeLogo img {
        width: 70%;
    }
    .lightVideoWrapper iframe {
        height: 30vh;
    }
    .lightVideoTitle {
        font-size: 1rem;
    }
    #m6records {
        height: 100vh;
        overflow: hidden; 
    }

    .m6Shadow {
        top: -116vh;
        height: 110vh;
    }
    .m6Logo {
        position: relative;
        top: -216vh;
    }
    .m6Sobre {
        top: -213vh;
        font-size: 1rem;
        padding: 20px 10px;
        z-index: 10;
    }
    .m6Video video {
        max-width: unset;
        width: unset;
    }
    .m6Logo img {
        width: 35%;
    }
    .albumDate {
        font-size: 1rem;
        margin: 0px 0px;
    }
    .albumDesc {
        font-size: 1rem;
        line-height: 1rem;
    }
    .footerLogo img {
        height: 80px;
        margin: 10px 20px;
    }
}    

