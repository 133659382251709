/* 
Filename: lightbox.css;
Purpose: css definitions for lightbox;
Author: Cello Nascimento;
Email: cello@m6prod.com;
*/
.lightBox {
  position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
    z-index: 100;
    transition: 1s;
}
.lightBoxActive {
  display: block!important;
}
.lightVideoWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
}
.lightVideoWrapper i {
    font-size: 3rem;
    display: block;
}
.lightVideoWrapper a {
    color: var(--white);
    text-decoration: none;
    position: relative;
    top: 20px;
    transition: 0.4s;
}
.lightVideoWrapper a:hover {
    color: #1DB954;
}    
.lightVideoWrapper iframe {
    width: 100%;
    height: 70vh;
    border-radius: 10px;
    border: 2px solid var(--white);
}
.lightVideoWrapper img {
    width: 40%;
}
.lightVideoTitle {
    font-size: 2rem;
    font-weight: 500;
    display: block;
}
.lightVideoDesc {
    line-height: 1rem;
    font-weight: 300;
}
.lightVideoClose {
    max-width: 50px;
    position: absolute;
    right: 1%;
    top: 1%;
}
.lightVideoClose i {
    font-size: 3rem;
    cursor: pointer;
    transition: 0.4s;
}
.lightVideoClose i:hover {
    color: var(--pink);
}
.lightVideoDate {
    display: block;
}
.albumCover img {
    width: 70%;
    border-radius: 10px;
    border: 5px solid var(--white);
}
.albumTitle {
    font-size: 2.5rem;
}
.albumDate {
    font-size: 1.5rem;
    margin: 20px 0px;
}
.albumDesc {
    font-size: 1.5rem;
    line-height: 2rem;
}