#lancamentos {
    background-color: var(--lightorange);
    background: url('../img/hexlightorange.png');
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.lancamentosTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--lightorange);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--lightorange);
}
.lancamentosText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--lightorange); 
}
.lancamentosContent {
	background-color: var(--lightorange);
	border-radius: 10px;
	padding: 10px;
	margin: 5px;
	cursor: pointer;
	transition: 0.4s;
}
.lancamentosContent:hover {
	background-color: var(--pink);
}
.lancamentosContent img {
	max-width: 100%;
	border-radius: 10px;
	aspect-ratio: 1/1;
    object-fit: contain;
}