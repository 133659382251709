#sobre {
    background-color: var(--pink);
    background: url('../img/hexpink.png');
    background-attachment: fixed;
    background-size: cover;
    border-bottom: 15px solid var(--white);
}
.sobreTitle {
	display: block;
	font-size: 2.5rem;
	font-weight: 600;
	color: var(--pink);
	margin-bottom: 20px;
	border-bottom: 3px dotted var(--pink);
}
.sobreText {
	background-color: var(--white);
	margin: 50px 0px;
	padding: 30px;
	font-size: 1.2rem;
	font-weight: 200;
	border-radius: 10px;
	border: 3px solid var(--pink); 
}